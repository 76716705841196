//
// summernote.scss
//

.fa.fa-scissors {
  &:before {
    content: "";
  }
}

.fa.fa-files-o {
  &:before {
    content: "";
  }
}

.fa.fa-repeat {
  &:before {
    content: "";
  }
}

.fa.fa-chain-broken {
  &:before {
    content: "";
  }
}

.fa.fa-picture-o {
  &:before {
    content: "";
  }
}

.fa.fa-youtube-play {
  &:before {
    content: "";
    font-family: "Font Awesome 5 Brands";
  }
}