.bg-masterhead {
    min-height: 100vh;
    min-width: 100vw;
    background-attachment: fixed;
    background-position: center;
    background: url("assets/images/unimed art.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.clickable {
    cursor: pointer;
}

.inline-input {
    border: 0px;
    border-bottom: 2px solid #000000;
}

.scrollable {
    overflow: auto;
}

.sm-hori-scroll::-webkit-scrollbar {
    // scrollbar-width: thin;
    background-color: #efefef;
    border-radius: 0.25rem;
    height: 6px;
}

.sm-hori-scroll::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: #b9b9b9;
    height: 6px;
}

::ng-deep .modal-backdrop {
    z-index: 1001 !important;
}

.pointer {
    cursor: pointer;
}

.input-group-text {
    padding: 0.40rem 0.9rem !important;
}

.content,
.unimed-left-sidebar {
    height: 100%;
}

.unimed-left-sidebar {
    overflow-x: hidden;
    //overflow-y: auto;

    &:not(.no-background) {
        background-color: #ffffff;
    }

    border-radius: 0.5rem;
}

.no-scroll {
    overflow-y: hidden !important;
}

.local-breadcrumb {
    color: #032f3cff;
    font-variant: small-caps;

    .item {
        &:not(.active) {
            color: #032f3cff;
        }

        &.active {
            color: #ccccccff !important;
        }
    }
}

.btn {
    border-radius: 0.5rem !important;
    white-space: nowrap;
}

.title-last {
    font-family: Montserrat;
    font-weight: bold;
    line-height: 0.8rem;
    font-size: 0.8rem;
    font-variant: small-caps;
    color: #cccccc;
}

.title {
    font-family: Montserrat;
    line-height: 1.6rem;
    font-size: 1.6rem;
    font-weight: bold;
    font-variant: small-caps;
    color: #000;
}

.overlay {
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2000;
}

.local-internal-scroll {
    height: 100%;
    position: relative;

    .scroll-content {
        position: absolute;
        display: flex;
        flex-direction: column;

        /* for Firefox */
        min-height: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        overflow-y: auto;
        overflow-x: hidden;
    }
}

.no-wrap {
    white-space: nowrap;
}

.brief-user {
    font-family: Montserrat;

    .firstname {
        line-height: 1.6rem;
        font-size: 1.6rem;
        font-weight: 500;
        // font-variant: small-caps;
        color: #032d3a !important;
    }

    .lastname {
        line-height: 0.9rem;
        font-size: 0.9rem;
        font-variant: small-caps;
        font-weight: 500;
        color: #032d3a !important;
    }

    .years {
        line-height: 0.8rem;
        font-size: 0.8rem;
        // font-variant: small-caps;
        font-weight: 300;
        color: #032d3a !important;
    }

    .date {
        line-height: 0.7rem;
        font-size: 0.7rem;
        // font-variant: small-caps;
        font-weight: 300;
        color: #cccccc !important;
    }

    hr {
        border-top-width: 2px !important;
    }
}

.brief-items {
    font-family: Montserrat;
    .header {
        cursor: pointer;
        &:hover {
            background-color: #66c8c81a;
        }
    }

    .title {
        color: #66c8c8ff;
        cursor: pointer;
        line-height: 0.8rem;
        font-size: 0.8rem;
        font-weight: 500;
        font-variant: small-caps;

        .noti {
            line-height: 0.6rem;
            font-size: 0.6rem;
            background-color: #66c8c8ff;
            border-radius: 50%;
            color: white;
            height: fit-content;
            width: fit-content;
            padding: 2px 4px 2px 4px;
        }
    }

    .icon {
        color: #66c8c8ff;
        cursor: pointer;
    }

    .level-1 {
        color: #032d3a;
        font-weight: 600;
        font-size: 0.95rem;
    }

    .level-2 {
        color: #032d3a;
        font-weight: 300;
        font-size: 0.85rem;

        .level-2-5 {
            color: #032d3a;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 0.75rem;
            font-variant: small-caps;

            i {
                margin-left: 2px;
                font-size: 0.55rem;
                color: #66c8c8ff;
            }
        }
    }

    .level-3 {
        font-size: 0.7rem;
        font-weight: 300;
    }

    .more {
        font-size: 0.7rem;
        font-weight: 400;
        text-align: right;
        color: #cccccc;
        cursor: pointer;
        margin-left: auto;
    }

    hr {
        border-top-width: 2px !important;
    }
}

.custom-tooltip {
    background-color: #ffffff;
    color: #000;
    border-radius: 3px;
    padding: 0.5rem 1rem !important;
    border: 2px solid var(--primary);
    white-space: nowrap;

    .ct-header {
        font-weight: 600;
        font-size: 0.8rem;
    }
    .ct-date {
        font-size: 0.8rem;
        span {
            font-size: 0.8rem;
        }
    }
    .ct-age {
        font-size: 0.8rem;
        span {
            font-weight: 600;
            font-size: 0.8rem;
        }
    }
    .ct-percentil {
        font-size: 0.8rem;
        span {
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
}

.tooltip-header {
    font-weight: bold;
    margin-bottom: 4px;
}
