//
// color-picker.scss
//

.color-picker {
    border: 0px solid transparent !important;
    box-shadow: 0 0 8px 0 rgba(154, 161, 171, 0.3);

    .arrow.arrow-bottom {
        border-width: 10px 10px !important;
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $gray-400 rgba(0, 0, 0, 0) !important;
    }
}