.new-patient-wizard {
    transition: all 0.2s ease-out;

    .title {
        margin-left: calc(10px + #{$leftbar-width-collapsed});
        z-index: 1;
        padding-left: 20px;
        margin-top: 0;
        font-size: 1rem;
    }

    .content {
        margin-left: calc(10px + #{$leftbar-width-collapsed});
        z-index: 1;
        background-color: #ffffff;
        border-radius: 0.5rem;
        padding: 10px 20px;

        h4 {
            margin: 0;
        }
    }

    .steps {
        width: $leftbar-width-collapsed;
        position: fixed;
        z-index: 1;

        // Sidebar Menu
        #steps-menu {
            .metismenu {
                border-radius: 0.5rem;
                background-color: white;
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;

                    > a {
                        h4 {
                            color: $menu-item;
                        }

                        transition: all 0.4s;
                        font-family: $font-family-secondary;

                        padding: 10px 20px;

                        i {
                            font-size: 1.125rem;
                            margin-right: 20px;
                            margin-left: 5px;
                        }

                        span {
                            display: none;
                            padding-left: 25px;
                        }
                    }

                    &:hover {
                        h4 {
                            color: $menu-item-hover;
                        }
                    }

                    &:active,
                    &.active {
                        h4 {
                            color: $menu-item-active;
                        }
                    }

                    &:hover,
                    &:active,
                    &.active {
                        border-radius: 0.5rem;
                        background-color: $gray-100;

                        > a {
                            position: relative;
                            width: calc(190px + #{$leftbar-width-collapsed});
                            transition: none;

                            span {
                                display: inline;
                            }
                        }

                        a.open,
                        a.active {
                            :after {
                                display: none;
                            }
                        }

                        > ul {
                            display: block;
                            left: $leftbar-width-collapsed;
                            position: absolute;
                            width: 190px;
                            height: auto !important;
                            box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);

                            ul {
                                box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);
                            }
                            a {
                                box-shadow: none;
                                padding: 8px 20px;
                                position: relative;
                                width: 190px;
                                z-index: 6;
                                &:hover {
                                    color: $menu-item-hover;
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $bg-leftbar;

                    li {
                        &:hover {
                            > ul {
                                display: block;
                                left: 190px;
                                height: auto !important;
                                margin-top: -36px;
                                position: absolute;
                                width: 190px;
                            }
                        }

                        > a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }
                    li.active {
                        a {
                            color: $menu-item-active;
                        }
                    }
                }
            }
        }
    }
}
