//
// Select2.scss
//

@import "~@ng-select/ng-select/themes/default.theme.css";


.ng-select.ng-select-single .ng-select-container {
    height: $input-height !important;
}

.ng-select .ng-select-container {
    border: $input-border-width solid $input-border-color !important;
}

.ng-dropdown-panel {
    box-shadow: $box-shadow;
    border: none !important;
    z-index: 99 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, 
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $gray-200 !important;
}

.ng-select {
    &.ng-select-focused {
        &:not(.ng-select-opened) > .ng-select-container {
            box-shadow: none !important;
        }
    }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: $primary;
    color: $white;
}



.select2-container {
    width: 100% !important;
    .select2-selection--single {
        border: 1px solid $input-border-color;
        height: 38px;
        outline: none;
        .select2-selection__rendered {
            line-height: 36px;
            padding-left: 12px;
        }
        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            right: 3px;
            b {
                border-color: darken($light, 15%) transparent transparent transparent;
                border-width: 6px 6px 0 6px;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent darken($light, 15%) transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px;
}

.select2-dropdown {
    border: 1px solid darken($light, 5%);
    box-shadow: $box-shadow;
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: lighten($light, 5%);
        .select2-search__field {
            border: 1px solid darken($light, 5%);
            outline: none;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary;
    }
    .select2-results__option[aria-selected=true] {
        background-color: $light;
        color: $dark;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: 38px;
        border: 1px solid $input-border-color !important;
        .select2-selection__rendered {
            padding: 1px 10px;
        }
        .select2-search__field {
            border: 0;
        }
        .select2-selection__choice {
            background-color: $primary;
            border: none;
            color: $white;
            border-radius: 3px;
            padding: 0 7px;
            margin-top: 7px;
        }
        .select2-selection__choice__remove {
            color: $white;
            margin-right: 5px;
            &:hover {
                color: $white;
            }
        }
    }
}