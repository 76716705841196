// Carousel
.carousel-item {
  outline: none !important;

  img {
    outline: none !important;
  }
}

.carousel-inner,
.carousel {
  outline: none !important;
}
