//Variables
$expand-width: 250px;
$no-expand-width: 60px;
$item-height: 40px;
$spacing: 10px;
$interval: 0.3s;
$icon-size: 1rem;
$font-size: 0.8rem;
$color-active: #032f3cff;
$color-hover: #939495ff;
$color: #939495ff;
$background-active: #e6eaebff;
$background-hover: #f5f6f7ff;
$background: #ffffffff;

.hovered_sidebar-menu {
    font-family: Montserrat;
    position: absolute;
    top: 0;
    left: $spacing;
    z-index: 50;

    &.style_shadowbox {
        &.lock_expanded {
            .hovered_sidebar-menu-container {
                .hovered_sidebar-menu-navigation {
                    box-shadow: 5px 0 10px -2px #00000033 !important;
                }
            }
        }

        .hovered_sidebar-menu-container {
            .hovered_sidebar-menu-navigation {
                &:hover {
                    box-shadow: 5px 0 10px -2px #00000033 !important;
                }
            }
        }
    }

    &.style_border {
        &.lock_expanded {
            .hovered_sidebar-menu-container {
                .hovered_sidebar-menu-navigation {
                    border: 2px solid #d0d4da !important;
                }
            }
        }

        .hovered_sidebar-menu-container {
            .hovered_sidebar-menu-navigation {
                &:hover {
                    border: 2px solid #d0d4da !important;
                }
            }
        }
    }

    &.style_noborder {
        &.lock_expanded {
            .hovered_sidebar-menu-container {
                .hovered_sidebar-menu-navigation {
                    // border: 2px solid #d0d4da !important;
                }
            }
        }

        .hovered_sidebar-menu-container {
            .hovered_sidebar-menu-navigation {
                &:hover {
                    // border: 2px solid #d0d4da !important;
                }
            }
        }
    }

    &.lock_expanded {
        .hovered_sidebar-menu-container {
            .hovered_sidebar-menu-navigation {
                width: $expand-width;

                ul {
                    li {
                        a {
                            transition-delay: 0s !important;

                            border-radius: 0rem 1.2rem 1.2rem 0rem !important;
                        }

                        &.active {
                            a {
                                color: $color-active !important;
                                background-color: $background-active !important;
                            }
                        }

                        &:hover {
                            a {
                                color: $color-hover;
                                background-color: $background-hover;
                            }
                        }
                    }
                }
            }
        }
    }

    &:not(.lock_expanded) {
        .hovered_sidebar-menu-container {
            .hovered_sidebar-menu-navigation {
                &:hover {
                    width: $expand-width;
                }
            }
        }
    }

    .hovered_sidebar-menu-container {
        position: relative;

        .hovered_sidebar-menu-navigation {
            position: relative;
            width: $no-expand-width;
            height: calc(100vh - #{$topbar-height} /*- 30px - 0.9rem*/ - 20px);
            background: $background;
            border-radius: 0.5rem;
            overflow: hidden;
            transition: $interval;
            transition-delay: $interval;

            &:hover {
                ul {
                    li {
                        a {
                            transition-delay: 0s !important;

                            border-radius: 0rem 1.2rem 1.2rem 0rem !important;
                        }

                        &.active {
                            a {
                                color: $color-active !important;
                                background-color: $background-active !important;
                            }
                        }

                        &:hover {
                            a {
                                color: $color-hover;
                                background-color: $background-hover;
                            }
                        }
                    }
                }
            }

            &:not(:hover) {
                ul {
                    li {
                        &.active {
                            a {
                                .icon {
                                    margin-left: $spacing;
                                    margin-right: $spacing;
                                    min-width: calc(#{$no-expand-width} - #{$spacing} - #{$spacing});

                                    font-weight: 500;
                                    border-radius: 50%;
                                    background-color: $background-active;
                                    color: $color-active;
                                }
                            }
                        }
                    }
                }
            }

            ul {
                position: absolute;
                top: $spacing;
                left: 0;
                width: 100%;
                padding: 0px;

                li {
                    cursor: pointer;
                    padding-right: $spacing;
                    margin-top: 2px;
                    position: relative;
                    list-style: none;
                    width: 100%;

                    a {
                        position: relative;
                        width: 100%;
                        display: flex;
                        text-decoration: none;
                        color: $color;
                        font-weight: 500;
                        transition: $interval;
                        transition-delay: $interval;

                        .icon {
                            position: relative;
                            display: block;
                            min-width: $no-expand-width;
                            height: $item-height;
                            line-height: $item-height;
                            text-align: center;

                            .fa-duotone {
                                font-size: $icon-size;
                            }
                        }

                        .label {
                            position: relative;
                            display: block;
                            height: $item-height;
                            line-height: $item-height;
                            font-size: $font-size;
                            white-space: nowrap;
                            //font-variant: small-caps;
                        }
                    }
                }
            }
        }
    }
}

.hovered_sidebar-content {
    &.lock_expanded {
        margin-left: calc(#{$expand-width} + #{$spacing} + #{$spacing});
    }

    &:not(.lock_expanded) {
        margin-left: $no-expand-width;
    }

    height: calc(100vh - #{$topbar-height} /*- 30px - 0.9rem*/ - 20px);
    overflow-x: hidden;
    overflow-y: auto;
}
