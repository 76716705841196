.d-local-block {
	display: block;
}

.d-local-inline {
	display: inline;
}

.d-local-run-in {
	display: run-in;
}

.d-local-flow-root {
	display: flow-root;
}

.d-local-table {
	display: table;
}

.d-local-flex {
	display: flex;
}

.d-local-grid {
	display: grid;
}

.d-local-ruby {
	display: ruby;
}

.d-local-subgrid {
	display: subgrid;
}

.d-local-inline table {
	display: inline table;
}

.d-local-list-item {
	display: list-item;
}

.d-local-table-row-group {
	display: table-row-group;
}

.d-local-table-header-group {
	display: table-header-group;
}

.d-local-table-footer-group {
	display: table-footer-group;
}

.d-local-table-row {
	display: table-row;
}

.d-local-table-cell {
	display: table-cell;
}

.d-local-table-column-group {
	display: table-column-group;
}

.d-local-table-column {
	display: table-column;
}

.d-local-table-caption {
	display: table-caption;
}

.d-local-ruby-base {
	display: ruby-base;
}

.d-local-ruby-text {
	display: ruby-text;
}

.d-local-ruby-base-container {
	display: ruby-base-container;
}

.d-local-ruby-text-container {
	display: ruby-text-container;
}

.d-local-contents {
	display: contents;
}

.d-local-none {
	display: none;
}

.d-local-inline-block {
	display: inline-block;
}

.d-local-inline-table {
	display: inline-table;
}

.d-local-inline-flex {
	display: inline-flex;
}

.d-local-inline-grid {
	display: inline-grid;
}

.d-local-initial {
	display: initial;
}

.d-local-unset {
	display: unset;
}

/* xs */
@media screen and (max-width: 575px) {
	.d-local-sm-only,
	.d-local-md-only,
	.d-local-lg-only,
	.d-local-xl-only,
	.d-local-sm-up,
	.d-local-md-up,
	.d-local-lg-up,
	.d-local-xl-up {
		display: none !important;
	}
}

/* sm */
@media screen and (min-width: 576px) and (max-width: 767px) {
	.d-local-xs-only,
	.d-local-md-only,
	.d-local-lg-only,
	.d-local-xl-only,
	.d-local-md-up,
	.d-local-lg-up,
	.d-local-xl-up,
	.d-local-xs-down {
		display: none !important;
	}
}

/* md */
@media screen and (min-width: 768px) and (max-width: 991px) {
	.d-local-xs-only,
	.d-local-sm-only,
	.d-local-lg-only,
	.d-local-xl-only,
	.d-local-lg-up,
	.d-local-xl-up,
	.d-local-xs-down,
	.d-local-sm-down {
		display: none !important;
	}
}

/* lg */
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.d-local-xs-only,
	.d-local-sm-only,
	.d-local-md-only,
	.d-local-xl-only,
	.d-local-xl-up,
	.d-local-xs-down,
	.d-local-sm-down,
	.d-local-md-down {
		display: none !important;
	}
}

/* xl */
@media screen and (min-width: 1200px) {
	.d-local-xs-only,
	.d-local-sm-only,
	.d-local-md-only,
	.d-local-lg-only,
	.d-local-xs-down,
	.d-local-sm-down,
	.d-local-md-down,
	.d-local-lg-down {
		display: none !important;
	}
}