//
// menu.scss
//

// Metis Menu Overwrite
@import "~metismenujs/scss/metismenujs";

.metismenu {
    padding: 0;

    li {
        list-style: none;

        a {
            &[aria-expanded="true"] {
                color: $menu-item-active !important;
            }
        }
    }
    ul {
        padding: 0;
        li {
            width: 100%;
        }
    }

    .mm-collapse:not(.mm-show) {
        display: none;
    }

    .mm-collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: 0.35s;
        transition-property: height, visibility;
    }
}

.nav-second-level,
.nav-thrid-level {
    li {
        a {
            padding: 8px 20px;
            color: $menu-item;
            display: block;
            position: relative;
            transition: all 0.4s;
            &:focus,
            &:hover {
                color: $menu-item-hover;
            }
        }

        > a.active {
            color: $menu-item-active;
        }
    }
}

// Wrapper
#wrapper {
    height: 100vh !important;
    overflow: hidden;
    width: 100%;
}

.second-side-menu {
    .slimscroll-menu {
        transition: all 0.2s ease-out;
        padding: 10px;
        overflow: hidden;

        .unimed-left-sidebar {
            overflow: auto;
            max-height: calc(100vh - #{$topbar-height} /*- 30px - 0.9rem*/ - 20px);
            margin-bottom: 10px;
        }
    }
}

//Content Page
.content-page {
    transition: all 0.2s ease-out;
    overflow: hidden;
    height: calc(100vh - #{$topbar-height} /*- 30px - 0.9rem*/);
    padding: 10px;

    &.show {
        &:not(.expand) {
            margin-left: $secondside-width + $leftbar-width;
        }

        &.expand {
            margin-left: $secondside-width-expand + $leftbar-width;
        }
    }
    &:not(.show) {
        &:not(.expand) {
            margin-left: $leftbar-width;
        }

        &.expand {
            margin-left: $leftbar-width;
        }
    }

    &:not(.searching) {
        margin-top: $topbar-height;
    }

    .searching {
        margin-top: 0px;
    }
}

// Sidemenu
.left-side-menu {
    width: $leftbar-width;
    --background: $bg-leftbar;
    padding: 10px 0;
    position: fixed;
    transition: all 0.2s ease-out;
    --box-shadow: 0 1px 1px rgba($dark, 0.1);
    z-index: 1;

    &:not(.searching) {
        bottom: 0;
        top: $topbar-height;
    }

    .searching {
        --top: 0;
    }
}
.second-side-menu {
    --background: $bg-leftbar;
    left: $leftbar-width;
    padding-left: 10px;
    padding-right: 0px;
    position: fixed;
    transition: all 0.2s ease-out;
    --box-shadow: 0 1px 1px rgba($dark, 0.1);
    z-index: 1;

    &:not(.expand) {
        width: $secondside-width;
    }

    &.expand {
        width: $secondside-width-expand;
    }

    &:not(.searching) {
        bottom: 0;
        top: $topbar-height;
    }

    .searching {
        top: 0;
    }
}

// Sidebar
#sidebar-menu {
    > ul {
        > li {
            &.disabled {
                cursor: not-allowed;
                > a {
                    cursor: not-allowed;
                    padding: 13px 20px;

                    h4,
                    i,
                    span {
                        color: #99999950;
                    }

                    > span {
                        vertical-align: middle;
                    }

                    i {
                        display: inline-block;
                        line-height: 1.0625rem;
                        margin: 0 10px 0 0;
                        text-align: center;
                        vertical-align: middle;
                        width: 20px;
                    }
                    .drop-arrow {
                        float: right;
                        i {
                            margin-right: 0;
                        }
                    }
                }
            }

            &:not(.disabled) {
                > a {
                    color: $menu-item;
                    display: block;
                    padding: 13px 20px;
                    position: relative;
                    transition: all 0.4s;
                    font-family: $font-family-secondary;
                    font-size: 0.925rem;

                    &:hover,
                    &:focus {
                        color: $menu-item-hover;
                        text-decoration: none;
                    }
                    &:active {
                        color: $menu-item-active;
                        text-decoration: none;
                    }
                    > span {
                        vertical-align: middle;
                    }

                    i {
                        display: inline-block;
                        line-height: 1.0625rem;
                        margin: 0 10px 0 0;
                        text-align: center;
                        vertical-align: middle;
                        width: 20px;
                    }
                    .drop-arrow {
                        float: right;
                        i {
                            margin-right: 0;
                        }
                    }
                }
            }

            > ul {
                padding-left: 35px;

                ul {
                    padding-left: 20px;
                }
            }

            &.active {
                a {
                    &.active {
                        color: $menu-item-active;
                    }
                }
            }
        }
    }
    .menu-arrow {
        transition: transform 0.15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: "Material Design Icons";
        text-rendering: auto;
        line-height: 1.5rem;
        font-size: 1.1rem;
        transform: translate(0, 0);
        &:before {
            content: "\F142";
        }
    }
    .badge {
        margin-top: 4px;
    }

    li.active {
        > a {
            > span.menu-arrow {
                transform: rotate(90deg);
            }
        }
    }

    .menu-title {
        padding: 10px 20px;
        letter-spacing: 0.05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: $menu-item;
        font-weight: $font-weight-semibold;
    }

    .waves-ripple {
        background: rgba($primary, 0.2);
    }
}

// Enlarge menu
.enlarged {
    .logo-box {
        width: $leftbar-width-collapsed !important;
    }

    .logo {
        span.logo-lg {
            display: none;
        }
        span.logo-sm {
            display: block;
        }
    }
    // Side menu
    .left-side-menu {
        position: absolute;
        //padding-top: 0;
        width: $leftbar-width-collapsed !important;
        z-index: 5;

        .slimScrollDiv,
        .slimscroll-menu {
            overflow: inherit !important;
            // height: auto !important;
        }
        .slimScrollBar {
            visibility: hidden;
        }

        // Sidebar Menu
        #sidebar-menu {
            .menu-title,
            .menu-arrow,
            .label,
            .badge,
            .collapse.in {
                display: none !important;
            }
            .nav.collapse {
                height: inherit !important;
            }

            > ul {
                > li {
                    position: relative;
                    white-space: nowrap;
                    cursor: not-allowed;

                    &.disabled {
                        > a {
                            cursor: not-allowed;
                            padding: 13px 20px;

                            h4,
                            i,
                            span {
                                color: #99999950;
                            }

                            i {
                                font-size: 1.125rem;
                                margin-right: 20px;
                                margin-left: 5px;
                            }

                            span {
                                display: none;
                                padding-left: 25px;
                            }
                        }
                    }

                    &:not(.disabled) {
                        > a {
                            padding: 13px 20px;
                            //min-height: 54px;

                            &:hover,
                            &:active,
                            &:focus {
                                color: $menu-item-hover;
                            }
                            i {
                                font-size: 1.125rem;
                                margin-right: 20px;
                                margin-left: 5px;
                            }

                            span {
                                display: none;
                                padding-left: 25px;
                            }
                        }

                        &:hover {
                            > a {
                                position: relative;
                                color: $menu-item-active;
                                background-color: $gray-100;
                                transition: none;
                            }

                            > ul {
                                display: block;
                                left: $leftbar-width-collapsed;
                                position: absolute;
                                width: 190px;
                                height: auto !important;
                                box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);

                                ul {
                                    box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);
                                }
                                a {
                                    box-shadow: none;
                                    padding: 8px 20px;
                                    position: relative;
                                    width: 190px;
                                    z-index: 6;
                                    &:hover {
                                        color: $menu-item-hover;
                                    }
                                }
                            }
                        }
                    }
                }

                ul {
                    padding: 5px 0;
                    z-index: 9999;
                    display: none;
                    background-color: $bg-leftbar;

                    li {
                        &:hover {
                            > ul {
                                display: block;
                                left: 190px;
                                height: auto !important;
                                margin-top: -36px;
                                position: absolute;
                                width: 190px;
                            }
                        }

                        > a {
                            span.pull-right {
                                position: absolute;
                                right: 20px;
                                top: 12px;
                                transform: rotate(270deg);
                            }
                        }
                    }
                    li.active {
                        a {
                            color: $menu-item-active;
                        }
                    }
                }
            }
        }
    }
    .second-side-menu {
        left: $leftbar-width-collapsed !important;
    }

    // Content Page
    .content-page {
        &.show {
            &:not(.expand) {
                margin-left: $secondside-width + $leftbar-width-collapsed;
            }

            &.expand {
                margin-left: $secondside-width-expand + $leftbar-width-collapsed;
            }
        }

        &:not(.show) {
            &:not(.expand) {
                margin-left: $leftbar-width-collapsed;
            }

            &.expand {
                margin-left: $leftbar-width-collapsed;
            }
        }
    }

    //Footer
    .footer {
        left: $leftbar-width-collapsed !important;
    }

    //User box
    .user-box {
        display: none;
    }
}

// Body min-height set
body.enlarged {
    min-height: 100vh;
}

@include media-breakpoint-down(sm) {
    body {
        overflow-x: hidden;
        padding-bottom: 80px;
    }
    .left-side-menu {
        display: none;
        z-index: 10 !important;
    }
    .second-side-menu {
        display: none;
        z-index: 10 !important;
    }
    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
        .second-side-menu {
            display: block;
        }
    }
    .content-page,
    .enlarged .content-page {
        margin-left: 0 !important;
        padding: 0 10px;
    }
    .pro-user-name {
        display: none;
    }
    .logo-box {
        display: none;
    }
}

/* =============
  Small Menu
============= */

.left-side-menu-sm {
    .logo-box {
        width: $leftbar-width-sm;
    }
    .left-side-menu {
        width: $leftbar-width-sm;
        text-align: center;
        #sidebar-menu {
            > ul {
                > li {
                    > a {
                        > i {
                            display: block;
                            font-size: 18px;
                            line-height: 24px;
                            width: 100%;
                            margin: 0;
                        }
                    }
                }
                ul {
                    padding-left: 0;
                    a {
                        padding: 10px 20px;
                    }
                }
            }
        }
        .menu-arrow,
        .badge {
            display: none !important;
        }

        .menu-title {
            background-color: $gray-100;
        }
    }
    .content-page {
        margin-left: $leftbar-width-sm;
        .footer {
            left: $leftbar-width-sm;
        }
    }
}

.enlarged.left-side-menu-sm {
    #wrapper {
        .left-side-menu {
            text-align: left;

            ul {
                li {
                    a {
                        i {
                            display: inline-block;
                            font-size: 18px;
                            line-height: 17px;
                            margin-left: 3px;
                            margin-right: 15px;
                            vertical-align: middle;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}

// Leftbar-dark
.left-side-menu-dark {
    .logo-box {
        --background-color: $bg-leftbar-dark;
    }

    .left-side-menu {
        background-color: $bg-leftbar-dark;
        box-shadow: none;
        border-right: 2px solid lighten($bg-leftbar-dark, 2%);

        #sidebar-menu {
            > ul {
                > li {
                    > a {
                        color: $menu-item-color-dark;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $menu-item-hover-color-dark;
                        }
                    }
                    > a.active {
                        color: $menu-item-active-color-dark;
                        background-color: lighten($bg-leftbar-dark, 5%);
                        border-right-color: $menu-item-active-color-dark;
                    }
                }
            }

            .menu-title {
                color: $gray-500;
            }
        }

        .nav-second-level,
        .nav-thrid-level {
            li {
                a {
                    color: $menu-item-color-dark;
                    &:focus,
                    &:hover {
                        background-color: transparent;
                        color: $menu-item-hover-color-dark;
                    }

                    &.active {
                        color: $menu-item-active-color-dark;
                    }
                }
            }
        }

        .nav-second-level,
        .nav-third-level {
            li.active {
                > a {
                    color: $menu-item-active-color-dark;
                }
            }
        }
    }
}

.enlarged.left-side-menu-dark #wrapper {
    .left-side-menu {
        #sidebar-menu {
            > ul {
                > li {
                    &:hover > a {
                        background-color: lighten($bg-leftbar-dark, 5%);
                    }
                }
            }
            .nav-second-level,
            .nav-thrid-level {
                li {
                    a {
                        &.active {
                            color: $menu-item-active;
                        }
                    }
                }
            }
        }
    }
}

.second-side-menu-sm {
    .logo-box {
        width: $leftbar-width-sm;
    }
    .second-side-menu {
        width: $leftbar-width-sm;
        text-align: center;
        #sidebar-menu {
            > ul {
                > li {
                    > a {
                        > i {
                            display: block;
                            font-size: 18px;
                            line-height: 24px;
                            width: 100%;
                            margin: 0;
                        }
                    }
                }
                ul {
                    padding-left: 0;
                    a {
                        padding: 10px 20px;
                    }
                }
            }
        }
        .menu-arrow,
        .badge {
            display: none !important;
        }

        .menu-title {
            background-color: $gray-100;
        }
    }
    .content-page {
        margin-left: $leftbar-width-sm;
        .footer {
            left: $leftbar-width-sm;
        }
    }
}

.enlarged.second-side-menu-sm {
    #wrapper {
        .second-side-menu {
            text-align: left;

            ul {
                li {
                    a {
                        i {
                            display: inline-block;
                            font-size: 18px;
                            line-height: 17px;
                            margin-left: 3px;
                            margin-right: 15px;
                            vertical-align: middle;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}

// Leftbar-dark
.second-side-menu-dark {
    .logo-box {
        --background-color: $bg-leftbar-dark;
    }

    .second-side-menu {
        background-color: $bg-leftbar-dark;
        box-shadow: none;
        border-right: 2px solid lighten($bg-leftbar-dark, 2%);

        #sidebar-menu {
            > ul {
                > li {
                    > a {
                        color: $menu-item-color-dark;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $menu-item-hover-color-dark;
                        }
                    }
                    > a.active {
                        color: $menu-item-active-color-dark;
                        background-color: lighten($bg-leftbar-dark, 5%);
                        border-right-color: $menu-item-active-color-dark;
                    }
                }
            }

            .menu-title {
                color: $gray-500;
            }
        }

        .nav-second-level,
        .nav-thrid-level {
            li {
                a {
                    color: $menu-item-color-dark;
                    &:focus,
                    &:hover {
                        background-color: transparent;
                        color: $menu-item-hover-color-dark;
                    }

                    &.active {
                        color: $menu-item-active-color-dark;
                    }
                }
            }
        }

        .nav-second-level,
        .nav-third-level {
            li.active {
                > a {
                    color: $menu-item-active-color-dark;
                }
            }
        }
    }
}

.enlarged.second-side-menu-dark #wrapper {
    .second-side-menu {
        #sidebar-menu {
            > ul {
                > li {
                    &:hover > a {
                        background-color: lighten($bg-leftbar-dark, 5%);
                    }
                }
            }
            .nav-second-level,
            .nav-thrid-level {
                li {
                    a {
                        &.active {
                            color: $menu-item-active;
                        }
                    }
                }
            }
        }
    }
}

// Leftbar with user
.user-pro-dropdown {
    background-color: $gray-100;
    box-shadow: none;
    padding: 15px 5px;
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;

    .dropdown-item {
        border-radius: 3px;

        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}

.search_page {
    height: 100vh;
}
