/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 4.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Icons Css File
*/

// Plugins
@import "custom/plugins/icons";
